import { applyMiddleware, combineReducers, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import AutenticacaoReducer from './reducers/AutenticacaoReducer';
import EmpresaReducer from './reducers/EmpresaReducer';
import OportunidadeReducer from './reducers/OportunidadeReducer';
import PromotorReducer from './reducers/PromotorReducer'

const basicReducer = combineReducers({
    AutenticacaoReducer,
    EmpresaReducer,
    OportunidadeReducer,
    PromotorReducer
});

export const store = createStore(
    basicReducer,
    applyMiddleware(ReduxThunk)
);
