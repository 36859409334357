import React, { Component } from 'react'
import './fileUploader.css'

class ImagePerfil extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return <div class="container-file">
            <form class="file-uploader">

                <label for="file-input">
                <div class="hover-imagem">
                    {this.props.loading === true ? 'Enviando ...' : 'Alterar imagem de perfil'}
                </div>
                {
                    this.props.children
                }
                </label>
                <input id="file-input" type="file" onChange={this.props.onChange}/>

            </form>
        </div>;
    }
}

export default ImagePerfil;