import axios from 'axios'
import firebase from 'firebase/app';
import 'firebase/auth';
import { projectId } from '../Firebase';

const request = require('request');

export const getUserToken = (callback) => {
    var user = firebase.auth().currentUser;
    user.getIdToken(true).then((token_id) => callback(token_id));
}

export const chamarFuncao = options => new Promise((resolve, rejected) => {
    getUserToken((token_id) => {
        request({
            method: options.method,
            url: `https://us-central1-${projectId}.cloudfunctions.net/${options.function}`,
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + token_id },
            body: options.body,
            json: true
        }, (res, errors, body) => {
            if (body.error) {
                rejected(body.error)
            } else if (body.result.data) {
                resolve(body.result.data)
            } else {
                rejected(body.result.error)
            }
        })
    })
})

/**
 * @name chamarNodeMongo
 * @date 16/12/20
 * @author Renahn Schultz
 * @description Reliza chamadas para a API node/mongo com base nos parâmetros passados
 * @param {Object} options Contém url que será chamada, e dados que serão passados para a requisição.
 */
export const chamarNodeMongo = options => new Promise((resolve, rejected) => {
    getUserToken((token_id) => {
        //let url = `http://localhost:3002/api/${options.url}`;
        let url = `https://logfreelance.tradepro.com.br/${options.url}`;
        let config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token_id
            }
        };
        let data = options.data;
        axios.post(url, data, config).then(
            response => resolve(response.data)
        )
    })
})


export const bigQuery = query => (
    chamarFuncao({ function: 'queryBigQuery', body: { data: { query } }, method: 'POST' })
)