import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import React, { Component } from 'react';
import { Grid, List } from 'semantic-ui-react';
import EditPerfil from './EditPerfil';
import EditSenha from './EditSenha';
import ImagePerfil from './FileUploader';
import './Perfil.css';
/**
 * @name salvarDadosNoFirebase
 * @date Criado em 18/12/2020
 * @author Diego jimenes
 * @description salvando dados no firebase 
 */
const salvarDadosNoFirebase = (id, dados, dataBase) => {
    return dataBase.firestore().collection('vendedores').doc(id).update(dados)
}
/**
 * @name enviarImagem
 * @date Criado em 18/12/2020
 * @author Diego jimenes
 * @description verificando se tem imagem para enviar, caso tenha deve enviar para store
 */
export const enviarImagem = (id, foto, dataBase = firebase) => {
    let file = foto
    if (file) {
        const fileRef = dataBase.storage().ref().child(
            `/vendedores/${id}/${file.name}`
        );
        return fileRef.put(file).then(() => salvarDadosNoFirebase(id, { foto: `/vendedores/${id}/${file.name}` }, dataBase))
    } else {
        return Promise.resolve()
    }
}
/**
 * @name buscarUrl
 * @date Criado em 18/12/2020
 * @author Diego jimenes
 * @description buscando url da imagem no storage
 */
export const buscarUrl = (foto, setState, dataBase = firebase) => {
    return dataBase.storage().ref().child(foto).getDownloadURL().then((url) => {
        setState({
            perfilImage: url
        })
    })
}
export default class Perfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enviandoImage: false,
            perfilImage: ''
        };
    }

    componentDidMount() {
        let { foto } = this.props.usuario
        if (foto) {
            buscarUrl(foto, (val) => this.setState(val))
        }
    }

    render() {
        const { usuario } = this.props
        let { nome, email, telefone, codigoVendedor, cpf, uid } = usuario
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '6%' }}>
                <>
                    <Grid columns={2}>
                        <Grid.Column>
                            <ImagePerfil loading={this.state.enviandoImage} onChange={(e) => {
                                let file = e.target.files[0]
                                this.setState({ enviandoImage: true }, () => {
                                    enviarImagem(uid, file).then(() => {
                                        buscarUrl(`/vendedores/${uid}/${file.name}`, (val) => this.setState(val)).then(() => {
                                            this.setState({ enviandoImage: false })
                                        })
                                    })
                                })
                            }}>
                                <div
                                    className={'foto-empresa circular'}
                                    style={{ backgroundImage: 'url("' + (this.state.perfilImage ? this.state.perfilImage : require('../../imagens/empresapadrao.png')) + '")' }}
                                ></div>
                            </ImagePerfil>
                        </Grid.Column>

                        <Grid.Column>
                            <List style={{ padding: 30 }} size="huge" relaxed>
                                <List.Item>
                                    <List.Icon name='user' size='large' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header className="header-list" as='p'>Nome</List.Header>
                                        <List.Description as='p'>{nome}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name='mail' size='large' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header className="header-list" as='p'>Email</List.Header>
                                        <List.Description as='p'>{email}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name='phone' size='large' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header className="header-list" as='p'>Telefone</List.Header>
                                        <List.Description as='p'>{telefone}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name='address card' size='large' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header className="header-list" as='p'>CPF</List.Header>
                                        <List.Description as='p'>{cpf}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name='qrcode' size='large' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header className="header-list" as='p'>Código Vendedor</List.Header>
                                        <List.Description as='p'>{codigoVendedor}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <EditPerfil dados={{ ...usuario }} />
                                </List.Item>
                                <List.Item>
                                    <EditSenha />
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid>
                </>
            </div>
        )
    }
}
