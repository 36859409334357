import React from 'react'
import { Step } from 'semantic-ui-react'

/**
 * @name Etapas
 * @date Criado em 17/12/20
 * @author Alexandre Poletto
 * @description Mostra as etapas de integração da empresa
 * @param {Object} props propriedades enviadas por parâmetro 
 */
const Etapas = (props) => {
  const { count } = props.oportunidades

  return <div style={{ marginBottom: 50 }}>
    <Step.Group fluid ordered size='tiny'>
      <Step completed>
        <Step.Content>
          <Step.Title>Cadastro</Step.Title>
        </Step.Content>
      </Step>

      <Step completed={count > 0}>
        <Step.Content>
          <Step.Title>1ª Oportunidade</Step.Title>
        </Step.Content>
      </Step>

      <Step
        disabled={count === 0}
        active={count > 0 && count < 5}
        completed={count >= 5}>
        <Step.Content>
          <Step.Title>5ª Oportunidade</Step.Title>
        </Step.Content>
      </Step>

      <Step
        disabled={count >= 0 && count < 5}
        active={count >= 5 && count < 10}
        completed={count >= 10}>
        <Step.Content>
          <Step.Title>10ª Oportunidade</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  </div>
}

export default Etapas
