import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { PureComponent } from 'react';
import { Area, AreaChart, XAxis, ResponsiveContainer, Tooltip } from 'recharts';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'white', padding: 15, borderRadius: 5 }}>
                <p style={{ textTransform: 'capitalize' }}>{moment(payload[0].payload.mes, 'MM').format('MMMM')} / {payload[0].payload.ano}</p>
                <p>{payload[0].payload.quantidade} oportunidades</p>
            </div>
        )
    }
    return null
}

class Grafico extends PureComponent {
    state = {
        grafico: []
    }

    componentDidMount() {
        this.processarDadosGrafico()
    }

    /**
    * @name processarDadosGrafico
    * @description processar os dados das oportunidades
    * do mês para mostrar no gráfico
    */
    processarDadosGrafico() {
        let grafico = []
        let oportunidades = { ...this.props.oportunidadesMes }
        delete oportunidades.ganhoRealMes

        _.map(oportunidades, op =>
            grafico = [
                ...grafico,
                {
                    mesAno: `${moment(op._id.mes, 'MM').format('MM')}/${op._id.ano}`,
                    ano: op._id.ano,
                    mes: op._id.mes,
                    quantidade: op.quantidade
                }
            ]
        )
        this.setState({ grafico: _.orderBy(grafico, ['ano', 'mes'], ['asc', 'asc']) })
    }

    render() {
        return (
            <div style={{ width: '100%', backgroundColor: '#f8f8f8', borderRadius: 5, padding: 10 }}>
                <h3>Oportunidades nos últimos 12 meses</h3>
                <ResponsiveContainer width="100%" height={160}>
                    <AreaChart
                        width={500}
                        height={160}
                        data={this.state.grafico}>
                        <XAxis dataKey="mesAno" />
                        <Tooltip content={<CustomTooltip />} />
                        <Area
                            type="monotone"
                            dataKey="quantidade"
                            stroke="#501e63"
                            fill="#501e63"
                            fillOpacity={0.9}
                            dot={{ stroke: 'white', strokeWidth: 1 }} />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

export default Grafico
