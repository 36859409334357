import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import Rebase from 're-base';

const development = false;

const prod_crend = { 
    apiKey: "AIzaSyCRhRzBetCRN_OawqNWkivEoHmWOfhEy5o",
    authDomain: "promotor-fa0af.firebaseapp.com",
    databaseURL: "https://promotor-fa0af.firebaseio.com",
    projectId: "promotor-fa0af",
    storageBucket: "promotor-fa0af.appspot.com",
    messagingSenderId: "363679882069"
}

const dev_crend = {
    apiKey: "AIzaSyDWiln-NaDZveobYzGKl9ijc7cfmDtebu8",
    authDomain: "promotor-desenvolvimento.firebaseapp.com",
    databaseURL: "https://promotor-desenvolvimento.firebaseio.com",
    projectId: "promotor-desenvolvimento",
    storageBucket: "promotor-desenvolvimento.appspot.com",
    messagingSenderId: "941992349682",
    appId: "1:941992349682:web:8b2df31c11fd7417"
}

const mainCrend = development ? dev_crend : prod_crend;
firebase.initializeApp(mainCrend);

export const db = firebase.firestore();
const config = Rebase.createClass(db);

export const auth = firebase.auth();
export const storage = firebase.storage();
export const projectId = mainCrend.projectId;
export const mainTable = projectId + (development ? '.promotordev' : '.promotor')
if (process.env.NODE_ENV === 'development') {
    window.firebaseDb = db;
    window.firebaseAuth = auth;
    window.firebaseStorage = storage;
}

export default config;