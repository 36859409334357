import _ from 'lodash';
import moment from 'moment';
import { chamarNodeApi } from '../../utils/Api';
import { querieExecutadasMes } from '../../utils/QueriesMongo';
import { OPORTUNIDADES_MES } from "../types";

/**
* @name buscarOportunidadesMes
* @description buscar no mongodb oportunidades dos
* últimos 12 meses, somar a taxa e valor
*/
export const buscarOportunidadesMes = () => async (dispatch) => {
    try {
        const inicioMes = moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD')
        const finalMes = moment().endOf('month').format('YYYY-MM-DD')

        const oportunidades = await chamarNodeApi(
            'roteiro/aggregate',
            {
                search: querieExecutadasMes({
                    dataInicio: inicioMes,
                    dataFinal: finalMes
                })
            }, 'POST'
        )

        const mesAtual = moment().format('MM')
        const anoAtual = moment().format('YYYY')
        const ganhoRealMes = _.find(oportunidades, oportunidade => {
            const mes = moment(oportunidade._id.mes, 'MM').format('MM')
            return String(oportunidade._id.ano) === anoAtual && mes === mesAtual
        })

        return dispatch({
            type: OPORTUNIDADES_MES,
            payload: { ...oportunidades, ganhoRealMes }
        })

    } catch (erro) {
        console.log(erro)
    }
}
