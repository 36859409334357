import { db } from "../../Firebase";
import { PROMOTORES_PRE_APROVADOS } from "../types";

export const buscarPromotoresAguardandoVerificacao = () => dispatch => {
    return new Promise((resolved, rejected) => db.collection('perfis')
        .onSnapshot(
            snap => {
                dispatch({
                    type: PROMOTORES_PRE_APROVADOS,
                    payload: snap.docs.map(promotor => promotor.data())
                });
                resolved();
            }
        ))
};