import { EMPRESAS, LOJAS_HOMOLOGADAS, MERCADOS, EMPRESAS_OPORTUNIDADES, COMISSAO } from '../types';

const reducers = {
    [EMPRESAS]: (state, action) => ({
        ...state, empresas: action.payload
    }),
    [LOJAS_HOMOLOGADAS]: (state, action) => ({
        ...state, lojas_homologadas: action.payload
    }),
    [MERCADOS]: (state, action) => ({
        ...state, mercados: action.payload
    }),
    [EMPRESAS_OPORTUNIDADES]: (state, action) => ({
        ...state, empresasComOportunidades: action.payload
    }),
    [COMISSAO]: (state, action) => ({
        ...state, comissao: action.payload
    }),
};

export default (state = { empresas: []}, action) => {
    const transform = reducers[action.type];
    if (transform) {
        return transform(state, action);
    }
    return { ...state };
}