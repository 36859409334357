import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Grid, Header, Icon, Image, List, Dimmer, Loader, Button } from 'semantic-ui-react';
import _ from 'lodash';
import { formatCNPJ } from '../../utils/CNPJ'
import Etapas from './Etapas'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import moment from 'moment';

const fbDefault = firebase
const fsDefault = firebase.firestore

/**
 * @name atribuirCadastro
 * @date Editado em 22/12/2020
 * @author Alexandre Poletto
 * @description atribui o vendedor a uma empresa
 * @param {function} firebase parametro exclusivo para testes
 */
export const atribuirCadastro = (id, vendedor, firebase = fbDefault) => (
    firebase.firestore().collection('empresas').doc(id).update({ vendedor })
)

/**
 * @name desatribuirCadastro
 * @date Editado em 22/12/2020
 * @author Alexandre Poletto
 * @description desatribui o vendedor de uma empresa
 * @param {function} firebase parametro exclusivo para testes
 */
export const desatribuirCadastro = (id, firebase = fbDefault, firestore = fsDefault) => (
    firebase.firestore().collection('empresas').doc(id).update({
        vendedor: firestore.FieldValue.delete()
    })
)

/**
 * @name CardEmpresa
 * @date Editado em 22/12/2020
 * @author Alexandre Poletto
 * @description Card com as informações detalhadas da empresa
 */
class CardEmpresa extends Component {

    state = {
        empresa: null,
        foto: null
    }

    componentDidMount() {
        this.setState({ empresa: this.props.empresa })
        if (this.props.usuario.foto) {
            this.downloadFoto(this.props.usuario.foto)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.empresa && !this.state.empresa) {
            this.setState({ empresa: this.props.empresa })
        }
    }

    // Caso encontre algum erro ao baixar a imagem da empresa, pega a imagem padrão
    handleImgError = e => {
        e.target.src = require('../../imagens/empresapadrao.png')
    }

    // Atribui o vendedor ao cadastro da empresa
    atribuir = () => {
        let vendedor = {
            codigo: this.props.usuario?.codigoVendedor,
            nome: this.props.usuario?.nome,
            telefone: this.props.usuario?.telefone,
            email: this.props.usuario?.email,
            foto: this.props.usuario?.foto ?? null,
            data: moment().format('YYYY-MM-DD')
        }
        atribuirCadastro(this.props.match.params.id, vendedor)
            .then(() => {
                this.setState({
                    empresa: {
                        ...this.state.empresa,
                        vendedor
                    }
                })
            })
    }

    // Desatribui o código do vendedor do cadastro da empresa
    desatribuir = () => {
        desatribuirCadastro(this.props.match.params.id)
            .then(() => {
                let empresa = this.state.empresa
                delete empresa.vendedor
                this.setState({ empresa })
            })
    }

    // Faz o download da imagem de perfil do vendedor
    downloadFoto = (imagem) => (
        firebase.storage().ref().child(imagem).getDownloadURL().then((foto) =>
            this.setState({ foto })
        )
    )

    render() {
        const { empresa } = this.state
        const { usuario, empresaComOportunidade } = this.props

        if (!empresa) {
            return (
                <Dimmer active>
                    <Loader indeterminate>Carregando empresa...</Loader>
                </Dimmer>
            )
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Header as='h1' style={{ marginBottom: 30 }}>
                            <Image centered src={empresa.imagemURL ?? require('../../imagens/empresapadrao.png')} onError={this.handleImgError} />
                            <Header.Content>
                                {empresa?.fantasia}
                                <Header.Subheader>{empresa?.razaoSocial}</Header.Subheader>
                            </Header.Content>
                            {_.isEmpty(empresa.vendedor) && (empresaComOportunidade?.count === undefined || empresaComOportunidade?.count < 10) &&
                                <Button
                                    floated='right'
                                    style={{ marginTop: 15, marginBottom: 10 }}
                                    content='Atribuir meu cadastro a essa empresa'
                                    icon='check'
                                    color='purple'
                                    basic
                                    onClick={this.atribuir} />
                            }
                            {empresa?.vendedor?.codigo === usuario?.codigoVendedor &&
                                <Button
                                    floated='right'
                                    style={{ marginTop: 15, marginBottom: 10 }}
                                    content='Desatribuir meu código desta empresa'
                                    icon='times'
                                    color='red'
                                    basic
                                    onClick={this.desatribuir} />
                            }
                        </Header>
                    </Grid.Column>

                    <Grid.Column width={16}>
                        <Etapas oportunidades={empresaComOportunidade ?? 0} />
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <Header as='h3'>
                            <Icon name='info' />
                            <Header.Content>INFORMAÇÕES</Header.Content>
                        </Header>
                        <Divider />
                        <List style={{ marginLeft: 42, marginBottom: 40 }}>
                            <List.Item><b>CNPJ:</b> {formatCNPJ(empresa?.cnpj)}</List.Item>
                            <List.Item><b>Email:</b> {empresa?.email}</List.Item>
                            <List.Item><b>Telefone:</b> {empresa?.telefone}</List.Item>
                        </List>

                        <Header as='h3'>
                            <Icon name='map signs' />
                            <Header.Content>ENDEREÇO</Header.Content>
                        </Header>
                        <Divider />
                        <List style={{ marginLeft: 42, marginBottom: 40 }}>
                            <List.Item><b>Logradouro:</b> {empresa?.endereco?.logradouro}, {empresa?.endereco?.numero}</List.Item>
                            <List.Item><b>Bairro:</b> {empresa?.endereco?.bairro}</List.Item>
                            <List.Item><b>CEP:</b> {empresa?.endereco?.cep}</List.Item>
                            <List.Item><b>Cidade:</b> {empresa?.endereco?.cidade}</List.Item>
                            <List.Item><b>Estado:</b> {empresa?.endereco?.estado}</List.Item>
                            {empresa?.endereco?.complemento !== '' && <List.Item><b>Complemento:</b> {empresa?.endereco?.complemento}</List.Item>}
                        </List>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        {empresa?.responsavel &&
                            <>
                                <Header as='h3'>
                                    <Icon name='user' />
                                    <Header.Content>RESPONSÁVEL</Header.Content>
                                </Header>
                                <Divider />
                                <List style={{ marginLeft: 42, marginBottom: 40 }}>
                                    <List.Item><b>Nome:</b> {empresa.responsavel?.nome}</List.Item>
                                    <List.Item><b>Email:</b> {empresa.responsavel?.email}</List.Item>
                                    <List.Item><b>Telefone:</b> {empresa.responsavel?.telefone}</List.Item>
                                </List>
                            </>}
                        <Header as='h3'>
                            <Icon name='user circle' />
                            <Header.Content>VENDEDOR</Header.Content>
                        </Header>
                        <Divider />
                        {!_.isEmpty(empresa?.vendedor) ?
                            <div style={{ marginLeft: 42, display: 'flex', flexDirection: 'row' }}>
                                {this.state.foto &&
                                    <Image style={{ objectFit: 'cover', height: 60, width: 60 }} src={this.state.foto} />
                                }
                                <List style={{ marginLeft: this.state.foto ? 10 : 0, marginTop: 0 }}>
                                    <List.Item><b>Nome:</b> {empresa?.vendedor?.nome}</List.Item>
                                    <List.Item><b>Email:</b> {empresa?.vendedor?.email}</List.Item>
                                    <List.Item><b>Telefone:</b> {empresa?.vendedor?.telefone}</List.Item>
                                </List>
                            </div>
                            :
                            <div style={{ marginLeft: 42 }}>Sem vendedor atribuído</div>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        empresa: _.find(state.EmpresaReducer.empresas, (empresa) => empresa.uid === props.match.params.id),
        empresaComOportunidade: _.find(state.EmpresaReducer.empresasComOportunidades, (empresa) => empresa._id === props.match.params.id),
        usuario: state.AutenticacaoReducer.usuario
    }
}

export default connect(mapStateToProps)(CardEmpresa)
