import React, { Component } from 'react';
import { Modal, Icon } from 'semantic-ui-react'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

/**
 * @name salvarDadosNoFirebase
 * @date Criado em 18/12/2020
 * @author Diego jimenes
 * @description salva os dados no firebase
 */
const salvarDadosNoFirebase = (id, dados, db) => {
    return db.firestore().collection('vendedores').doc(id).update(dados)
}

/**
 * @name updataPerfil
 * @date Criado em 18/12/2020
 * @author Diego jimenes
 * @description atualiza os dados do usuario
 */
export const updataPerfil = (id, dados, db = firebase) => {
    return salvarDadosNoFirebase(id, dados, db)
}

export default class EditarPerfil extends Component {
    constructor(props) {
        super(props);
        const { dados } = props
        let { nome, telefone, codigoVendedor, cpf } = dados
        this.state = {
            load: false,
            loadImage: false,
            errorSenha: '',
            codigo: codigoVendedor,
            nome,
            telefone,
            cpf,
        };
    }

    render() {
        var style = {
            styBtn: {
                border: 'none',
                background: '#662780',
                color: "#fff",
                fontSize: 15,
                width: '100%',
                height: 40,
                borderRadius: 60,
                outline: 'none',
                cursor: 'pointer'
            },
            input: {
                height: 40,
                borderRadius: 40,
                border: '1px solid lightgray',
                outline: 'none',
                marginBottom: 10,
                paddingLeft: 10,
            }
        }
        const { dados } = this.props
        let { uid } = dados
        return (
            <Modal
                closeIcon={<button style={{
                    position: 'absolute',
                    background: 'none',
                    right: 35,
                    top: 17,
                    border: 'none',
                    fontSize: 19,
                    outline: 'none',
                    cursor: 'pointer'
                }}><Icon name='times' /></button>}
                trigger={<button
                    style={{
                        border: 'none',
                        background: '#662780',
                        color: "#fff",
                        fontSize: 15,
                        width: 350,
                        height: 50,
                        borderRadius: 60,
                        outline: 'none',
                        cursor: 'pointer'
                    }}
                >
                    {'Editar Perfil'}
                </button>}>
                <Modal.Header>
                    {'Editar Perfil'}
                </Modal.Header>
                <Modal.Content image>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                            <label style={{ marginLeft: 10, fontWeight: 'bold' }}>Nome:</label>
                            <input
                                style={style.input}
                                name="nome"
                                type='text'
                                placeholder="Nome"
                                value={this.state.nome}
                                onChange={(e) => this.setState({ nome: e.target.value })} />
                            <label style={{ marginLeft: 10, fontWeight: 'bold' }}>Telefone:</label>
                            <input
                                style={style.input}
                                name="telefone"
                                type='phone'
                                placeholder="Telefone"
                                value={this.state.telefone}
                                onChange={(e) => this.setState({ telefone: e.target.value })} />
                            <label style={{ marginLeft: 10, fontWeight: 'bold' }}>código:</label>
                            <input
                                style={style.input}
                                name="código"
                                type='text'
                                placeholder="código"
                                value={this.state.codigo}
                                onChange={(e) => this.setState({ codigo: e.target.value })} />
                            <label style={{ marginLeft: 10, fontWeight: 'bold' }}>CPF:</label>
                            <input
                                style={style.input}
                                name="cpf"
                                type='text'
                                placeholder="CPF"
                                value={this.state.cpf}
                                onChange={(e) => this.setState({ cpf: e.target.value })} />
                            <button
                                onClick={() => this.setState({ load: true }, () => {
                                    updataPerfil(uid, {
                                        nome: this.state.nome,
                                        telefone: this.state.telefone,
                                        codigo: this.state.codigo,
                                        cpf: this.state.cpf
                                    }).then(() => {
                                        this.setState({ load: false })
                                    })
                                })}
                                style={
                                    this.state.load === false ? style.styBtn
                                        : {
                                            ...style.styBtn,
                                            opacity: 0.2,
                                        }
                                }>{this.state.load === false ? 'Editar' : `${'carregando'}...`}</button>
                            <span style={{ textAlign: 'center', color: "red" }}>{this.state.errorSenha}</span>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        );
    }
}
