import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Segment } from 'semantic-ui-react';
import { chamarNodeMongo } from '../functions/RequestFunctions';
import { salvarComissao } from '../redux/actions/EmpresaActions';
import { TOTAL_TAXA_VENDEDOR } from '../utils/QueriesMongo';
import moment from 'moment'
import Grafico from './Grafico';

const VALOR_COMISSAO = 0.10

class Home extends Component {

    /**
    * @name buscarComissao
    * @date Criado em 06/01/2020
    * @author Alexandre Poletto
    * @description busca no mongo as empresas que o
    * vendedor está vinculado para calcular a comissão
    * @param {Object} usuario informações do usuário
    * @param {Object} listaEmpresas lista das empresas cadastradas
    * @param {Function} node chamada da function do servidor node mongo
    */
    buscarComissao = async (usuario = this.props.usuario, listaEmpresas = this.props.empresas, node = chamarNodeMongo) => {
        try {
            let empresas = _.filter(listaEmpresas, e => e.vendedor?.codigo === usuario?.codigoVendedor)
            if (_.isEmpty(empresas)) {
                return false
            } else {
                _.map(empresas, (empresa) =>
                    node({ url: 'roteiro/aggregate', data: { search: TOTAL_TAXA_VENDEDOR(empresa) } })
                        .then(comissao => {
                            this.props.dispatch(salvarComissao(comissao))
                            return comissao
                        })
                )
            }
        } catch (erro) {
            console.log(erro)
            return erro
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.empresas !== this.props.empresas && !this.props.comissao) {
            this.buscarComissao()
        }
    }

    currency = valor => parseFloat(valor).toLocaleString('BRL', { style: 'currency', currency: 'BRL' })

    render() {
        const { oportunidadesMes } = this.props;
        const totalComissao = _.reduce(this.props.comissao, (soma, roteiro) => soma + (roteiro.taxa * VALOR_COMISSAO), 0)

        return (
            <div style={{ margin: 20 }}>
                <div style={styles.resumo}>Dashboard</div>
                {oportunidadesMes && <Grafico oportunidadesMes={oportunidadesMes} />}
                <div style={{ ...styles.divisao, ...styles.card, marginTop: 20 }}>
                    <div>
                        <h3>Cadastrados</h3>
                        <div style={styles.divisao} >
                            <div>
                                <span style={styles.subtitulo}>Promotores</span><br />
                                <span style={styles.titulo}>{this.props.promotores.length}</span><br />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: 30, marginTop: 25 }}>
                        <div style={styles.divisao} >
                            <div>
                                <span style={styles.subtitulo}>Empresas</span><br />
                                <span style={styles.titulo}>{this.props.empresas.length}</span><br />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 20, ...styles.card }}>
                    <h3>Comissão</h3>
                    <Grid>
                        {_.isEmpty(this.props.comissao) ?
                            <h4 style={styles.comissao}>Nenhuma comissão encontrada</h4>
                            :
                            <Grid.Column width={7}>
                                {_.map(_.groupBy(this.props.comissao, '_id.mes'), (grupo, mes) =>
                                    <div style={{ marginBottom: 25 }} key={mes}>
                                        <h3>{moment(grupo[0]._id.mes, 'M').format('MM')}/{grupo[0]._id.ano}</h3>
                                        <Segment color='black'>
                                            {_.map(_.orderBy(grupo, ['_id.fantasiaEmpresa'], ['asc']), (comissao, i) =>
                                                <Grid key={i} >
                                                    <Grid.Column width={12}>
                                                        <b style={styles.titulo}>{comissao._id.fantasiaEmpresa}</b><br />
                                                        <span style={styles.subtitulo}>Empresa</span>
                                                    </Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <b style={styles.titulo}>{this.currency(comissao.taxa * VALOR_COMISSAO)}</b><br />
                                                        <span style={styles.subtitulo}>Valor</span>
                                                    </Grid.Column>
                                                </Grid>
                                            )}
                                        </Segment>
                                    </div>)}
                                <div style={styles.total}>
                                    Total {this.currency(totalComissao)}
                                </div>
                            </Grid.Column>
                        }
                    </Grid>
                </div>
            </div>
        )
    }
}

const styles = {
    resumo: {
        fontSize: 22, fontWeight: 'bold', marginBottom: 20
    },
    titulo: {
        fontSize: 20, fontWeight: 'bold'
    },
    subtitulo: {
        fontSize: 15
    },
    icone: {
        backgroundColor: '#f0f0f0', width: 70, height: 70, borderRadius: 70 / 2, display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    divisao: {
        marginTop: 20, display: 'flex', flexDirection: 'row'
    },
    total: {
        marginTop: 35, fontSize: 20, fontWeight: 'bold'
    },
    comissao: {
        marginTop: 20,
        marginBottom: 20,
    },
    card: {
        backgroundColor: '#f8f8f8', borderRadius: 5, padding: 10
    }
}

export const buscarComissao = new Home().buscarComissao

export default connect((state) => {
    return {
        empresas: state.EmpresaReducer.empresas,
        promotores: state.PromotorReducer.promotores,
        tokenUsuario: state.AutenticacaoReducer.tokenUsuario,
        oportunidadesMes: state.OportunidadeReducer.oportunidadesMes,
        comissao: state.EmpresaReducer.comissao
    }
})(Home)
