import _ from 'lodash'
import React, { Component } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br';
import { connect } from 'react-redux'
import { Icon, Table, Dimmer, Loader, Tab, Input, Select, Button } from 'semantic-ui-react'
import Header from '../../componentes/Header'

const momentDF = moment

/**
 * @name defineComission
 * @date Criada em 28/12/2020
 * @author Vitor Savian 
 * @description Verifica se existe a data e se caso 
 * a data existir verifica se o periodo bate 3 meses atrás
 * @param {String} data do criadoEm da empresa
 * @param {Function} moment lib de datas
 */
export const defineComission = (data, moment = momentDF) => {
    const dataSub = moment().subtract(3, 'months').format('YYYY-MM-DD')
    if (data !== undefined) {
        const comissao = moment(dataSub).isBefore(data)
        return comissao
    } else {
        return false
    }
}

/**
 * @name LinhaEmpresa
 * @date alterada em 28/12/20
 * @author Renahn Schultz
 * @description Renderiza cada linha de empresa dentro da tabela de empresas
 * @param {Object} props dados referente a empresa e dados utilizado para fazer validações
 */
const LinhaEmpresa = (props) => {
    const publicado = _.find(props.empresasComOportunidades, empresa => empresa._id === props.uid)
    const comissao = defineComission(props.criadoEm)
    return (
        <Table.Row positive={publicado !== undefined} negative={publicado === undefined} key={props.key} onClick={props.redirect(props.uid)} style={{ cursor: 'pointer' }}>
            <Table.Cell collapsing>
                <img alt={props.fantasia} onError={e => e.target.src = require('../../imagens/empresapadrao.png')} src={props.imagemURL ?? require('../../imagens/empresapadrao.png')} width='48px' height='48px' />
            </Table.Cell>
            <Table.Cell>
                <span style={{ fontWeight: 'bold', fontSize: 16 }}>{props.fantasia}</span><br />
                <span style={{ fontSize: 14, color: 'grey' }}>{props.razaoSocial}</span>
            </Table.Cell>
            <Table.Cell>{props.email}</Table.Cell>
            <Table.Cell>{props.telefone}</Table.Cell>
            <Table.Cell>
                <Icon name={publicado ? 'checkmark' : 'times'} />
                {publicado ? 'Publicado' : 'Não Publicado'}
            </Table.Cell>
            {comissao ?
                <Table.Cell positive> <Icon name="check" size="large" /> Em Período de Comissão </Table.Cell>
                :
                <Table.Cell
                    warning={props.criadoEm ? true : false}
                    negative={props.criadoEm ? false : true}
                >
                    <Icon name={props.criadoEm ? "times" : "bug"} size="large" /> {props.criadoEm ? "Fechado Período de Comissão" : "Sem Período de Comissão"}
                </Table.Cell>
            }
        </Table.Row>
    )
}

/**
 * @name TableDefault
 * @date 18/12/20
 * @author Renahn Schultz
 * @description Tabela padrão de exibição das empresas na lista de empresas.
 */
const TableDefault = (props) => (
    <Table celled striped >
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell colSpan='2'>Empresa</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Telefone</Table.HeaderCell>
                <Table.HeaderCell>1ª Oport.</Table.HeaderCell>
                <Table.HeaderCell>Período Comissão</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {props.children}
        </Table.Body>
    </Table>
)

/**
 * @name tabs
 * @date 18/12/20
 * @author Renahn Schultz
 * @description Responsável por identificar as abas do componente tabs e o que renderizar em cada.
 */
const tabs = (props) => [
    {
        menuItem: 'Todas Empresas',
        render: () => <TableDefault>
            {_.orderBy(props.empresas, ['fantasia', 'desc']).map((empresa, i) => <LinhaEmpresa {...props} {...empresa} key={i} />)}
        </TableDefault>
        ,
    },
    {
        menuItem: 'Minhas Empresas',
        render: () => <TableDefault>
            {_.reduce(props.empresas, function (result, value, key) {
                if (value?.vendedor?.codigo === props.usuario.codigoVendedor) {
                    result.push(value);
                }
                return result;
            }, []).map((empresa, i) => <LinhaEmpresa {...props} {...empresa} key={i} />)}
        </TableDefault>
        ,
    },
]


/**
 * @name Empresas
 * @date 16/12/20
 * @author Renahn Schultz
 * @description Responsável por exibir as empresas cadastradas e se publicaram oportunidades
 */
class Empresas extends Component {

    state = {
        filtroEmpresas: [],
        filtroVendedor: 'todos',
        filtro: ''
    }

    /**
     * @name buscarEmpresas
     * @date 13/04/2021
     * @author Alexandre Poletto
     * @description Filtro de empresa por nome e vendedor
     * @param value valor digitado
     * @param value filtro de vendedor selecionado
     */
    buscarEmpresas = (value, filtroVendedor) => {
        let filtroEmpresas = []
        let filtro = _.filter(this.props.empresas, empresa =>
            _.includes(empresa?.fantasia?.toLowerCase(), value?.toLowerCase())
        )
        if (filtroVendedor === 'com') {
            filtroEmpresas = _.filter(filtro, empresa => !_.isEmpty(empresa.vendedor))
        } else if (filtroVendedor === 'sem') {
            filtroEmpresas = _.filter(filtro, empresa => _.isEmpty(empresa.vendedor) || empresa.vendedor === undefined || empresa.vendedor === null)
        } else {
            filtroEmpresas = filtro
        }
        this.setState({ filtro: value, listaEmpresas: filtroEmpresas })
    }

    options = [
        { key: 'todos', text: 'Todos', value: 'todos' },
        { key: 'com', text: 'Com vendedor', value: 'com' },
        { key: 'sem', text: 'Sem vendedor', value: 'sem' },
    ]

    render() {
        const { empresasComOportunidades, usuario, history } = this.props
        const empresas = _.isEmpty(this.state.listaEmpresas) ? this.props.empresas : this.state.listaEmpresas
        const redirect = (id) => () => history.push(`/empresas/${id}`)

        if (_.isEmpty(empresas)) {
            return (
                <Dimmer active>
                    <Loader indeterminate>Carregando empresas...</Loader>
                </Dimmer>
            )
        }

        return (
            <div>
                <Header title='Empresas' subtitle='Lista de empresas cadastradas no Promotor Freelance.' icon='building' />
                <Input
                    style={{ marginBottom: 10 }}
                    fluid action
                    onChange={(e, data) => this.buscarEmpresas(data.value, this.state.filtroVendedor)}
                    value={this.state.filtro}
                    placeholder='Procure a empresa pelo nome fantasia' >
                    <input />
                    <Select
                        button basic floating
                        options={this.options}
                        onChange={(e, data) => {
                            this.setState({ filtroVendedor: data.value })
                            return this.buscarEmpresas(this.state.filtro, data.value)
                        }}
                        value={this.state.filtroVendedor}
                        defaultValue={this.state.filtroVendedor} />
                    <Button onClick={() => {
                        this.setState({ filtroVendedor: 'todos' })
                        return this.buscarEmpresas('', 'todos')
                    }}>Limpar</Button>
                </Input>
                <Tab menu={{ secondary: true, pointing: true }} panes={tabs({ redirect, empresasComOportunidades, empresas, usuario })} />
            </div >
        )
    }
}

const mapStateToProps = state => ({
    empresas: state.EmpresaReducer.empresas,
    empresasComOportunidades: state.EmpresaReducer.empresasComOportunidades,
    usuario: state.AutenticacaoReducer.usuario
})

export default connect(mapStateToProps)(Empresas)