import {
    AUTENTICAR_USUARIO,
    DESAUTENTICA_USUARIO
} from "../types";

export const autenticarUsuario = (usuario, tokenUsuario) => {
    return {
        type: AUTENTICAR_USUARIO, usuario, tokenUsuario
    }
}

export const desautenticaUsuario = () => ({
    type: DESAUTENTICA_USUARIO
});