import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Form, Message } from 'semantic-ui-react';
import logo from '../../imagens/promotor.png';
import { auth } from './../../Firebase';

const authDf = auth
 /**
 * @name logar
 * @date Criado em 18/12/2020
 * @author Diego jimenes
 * @description esta função trata as credenciais do usuario e verifica se ele tem acesso ou não 
 * @param {Object} setState função de callback que executa um setState
 * @param {Function} email email do usuario
 * @param {Function} senha  senha do usuario
 */
export const logar = (setState, email, senha, auth = authDf) => {
    auth.signInWithEmailAndPassword(email, senha)
        .then((res) => {
            res.user.getIdTokenResult().then((token) => {
                if (token.claims.perm === 'vendedor') {
                    console.log('vendedor')
                    setState({ carregando: false, error: '' })
                } else {
                    console.log('não é vendedor')
                    setState({ carregando: false, error: 'Usuario ou senha incorreto.' }, () => auth.signOut())
                }
            })
        }).catch((err) => {
            setState({ carregando: false }, () => console.error(err))
        })
}

class Login extends Component {
    state = { email: '', senha: '', carregando: null, error: '' };

    logar = () => this.setState({ carregando: true }, () =>
        logar((val, callback) => this.setState(val, callback), this.state.email, this.state.senha)
    );


    handleChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({ [name]: value, error: '' });
    }

    render() {
        return (
            <>
                <svg className="Pol_gono_1-login" viewBox="0 0 402 350">
                    <path id="Pol_gono_1-login" d="M 200.9999389648438 0 L 402 350 L 0 350 Z">
                    </path>
                </svg>
                <svg className="Pol_gono_2-login" viewBox="0 0 402 350">
                    <path id="Pol_gono_2-login" d="M 200.9999847412109 0 L 402 350 L 0 350 Z">
                    </path>
                </svg>
                <Form onSubmit={this.logar} className='login-content' style={{ top: "50%", transform: "translateY(-50%)" }}>
                    <img src={logo} alt='Logo' className='login-img' />
                    <Input
                        fluid
                        placeholder='E-mail'
                        style={{ marginBottom: 10 }}
                        type='email'
                        value={this.state.email}
                        name='email'
                        onChange={this.handleChange}
                        disabled={this.state.carregando} />
                    <Input
                        fluid
                        placeholder='Senha'
                        type='password'
                        style={{ marginBottom: 15 }}
                        value={this.state.senha}
                        name='senha'
                        onChange={this.handleChange}
                        disabled={this.state.carregando} />
                    <Button
                        color='purple'
                        fluid
                        content="Entrar"
                        type='submit'
                        loading={this.state.carregando}
                        disabled={this.state.carregando} />
                    {this.state.error ? <Message color='red'>{this.state.error}</Message> : <></>}
                </Form>
            </>
        )
    }
}

const mapStateToProps = state => {
    const { firebaseReady, usuario, autenticado } = state.AutenticacaoReducer;
    return { autenticado, usuario, firebaseReady };
}

export default connect(mapStateToProps)(Login);