import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

export default (props) => {
    const { title, subtitle, icon, button: Button } = props;
    return (
        <>
            <Header as='h2' >
                <div style={{ float: "right" }}>
                    {Button}
                </div>
                <Icon name={icon} />
                <Header.Content>
                    {title}
                    <Header.Subheader>{subtitle}</Header.Subheader>
                </Header.Content>
            </Header>
        </>
    )
}
