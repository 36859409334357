import Empresas from './containers/empresas/Empresas';
import CardEmpresa from './containers/empresas/CardEmpresa';
import Home from './containers/Home';
import Login from './containers/login/Login';
import Perfil from './containers/perfil/Perfil';

const exibir = true;
const externo = true;
const todasPerm = ['vendedor'];

export default [
    { title: 'Login', path: "/login", component: Login, menu: false, externo },
    { title: 'Login', path: "/", component: Login, menu: false, externo },
    { title: 'Home', path: "/dashboard", component: Home, menu: true, icon: 'home', perms: todasPerm, exibir },
    { title: 'Empresas', path: "/empresas", component: Empresas, menu: true, icon: 'building', perms: todasPerm, exibir },
    { title: 'Dados Empresa', path: "/empresas/:id", component: CardEmpresa, menu: true, perms: todasPerm },
    { title: 'Perfil', path: "/perfil", component: Perfil, menu: true, icon: 'bug', perms: todasPerm },
];
