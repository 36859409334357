import {
    AUTENTICAR_USUARIO,
    DESAUTENTICA_USUARIO,
    LOGADO,
    NAO_LOGADO
} from "../types";


const INITIAL_STATE = {
    firebaseReady: false,
    autenticado: NAO_LOGADO,
    empresa: null,
    usuario: null,
    tokenUsuario: null,
};

const table = {
    [AUTENTICAR_USUARIO]: (state, action) => ({
        ...state,
        usuario: action.usuario,
        tokenUsuario: action.tokenUsuario,
        firebaseReady: true,
        autenticado: !!action.usuario ? LOGADO : NAO_LOGADO
    }),
    [DESAUTENTICA_USUARIO]: (state, action) => ({
        ...state,
        usuario: null,
        autenticado: NAO_LOGADO,
        tokenUsuario: null,
        firebaseReady: true,
    })
};

export default (state = INITIAL_STATE, action) => {
    const transform = table[action.type];
    if (transform) return transform(state, action);
    return state;
}