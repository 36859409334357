import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { Icon, List, Divider, Image } from 'semantic-ui-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import imagem from "./imagens/empresapadrao.png";
import logo_branca from './imagens/promotor_branco.png';
import firebase from 'firebase/app';
import 'firebase/auth';
import { connect } from 'react-redux';
import _ from 'lodash';
import menus from './menus.js'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
  },
  drawerOpen: {
      background: '#4f1e63',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
      }),
  },
  drawerClose: {
      background: '#4f1e63',
      transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
      },
  },
  toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
  },
  content: {
      marginLeft: 70,
      flexGrow: 1,
      padding: theme.spacing(3),
  },
}))

function Menu(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const tokenUsuario = props.tokenUsuario;

  const handleDrawerOpen = () => {
      setOpen(true);
  };

  const handleDrawerClose = () => {
      setOpen(false);
  };
  return (
      <div>
          <CssBaseline />
          <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
              })}
              classes={{
                  paper: clsx({
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                  }),
              }} >
              <div className={classes.toolbar}>
                  {!open ?
                      <IconButton onClick={handleDrawerOpen}>
                          <Icon style={{ color: '#f2f2f2', marginTop: 10 }} name='sidebar' />
                      </IconButton>
                      :
                      <div style={{ marginTop: 8 }}>
                          <IconButton onClick={handleDrawerClose}>
                              <Image src={logo_branca} style={{ width: 120, marginRight: 30, marginBottom: -5 }} />
                              <Icon style={{ color: '#f2f2f2' }} name='chevron left' />
                          </IconButton>
                      </div>}
              </div>
              <Divider />
              <List>
                  <Tooltip title='Perfil' placement="right" arrow>
                      <ListItem alignItems="center" button component={Link} to={'/perfil'}>
                          <ListItemAvatar>
                              <Avatar alt={tokenUsuario.name} src={imagem} />
                          </ListItemAvatar>
                          <ListItemText style={{ color: '#e6e6e6' }} primary={tokenUsuario.name} />
                      </ListItem>
                  </Tooltip>
              </List>
              <Divider />
              <List>
                  {_.filter(menus, 'exibir').map((menu, i) => (
                      <Tooltip key={i} title={menu.title} placement="right" arrow>
                          <ListItem button component={Link} to={menu.path}>
                              <ListItemIcon>
                                  <Icon name={menu.icon} size='large' style={{ marginLeft: 8, color: '#e6e6e6' }} />
                              </ListItemIcon>
                              <ListItemText style={{ color: '#e6e6e6' }} primary={menu.title} />
                          </ListItem>
                      </Tooltip>
                  ))}
              </List>
              <Divider />
              <List>
                  <Tooltip title='Sair' placement="right" arrow>
                      <ListItem button onClick={() => firebase.auth().signOut()}>
                          <ListItemIcon><Icon name='sign-out' size='large' style={{ marginLeft: 8, color: '#e6e6e6' }} /></ListItemIcon>
                          <ListItemText style={{ color: '#e6e6e6' }} primary='Sair' />
                      </ListItem>
                  </Tooltip>
              </List>
          </Drawer>
      </div>
  );
}

const mapStateToProps = (state, props) => {
    return {
        user: state.AutenticacaoReducer.usuario,
        tokenUsuario: state.AutenticacaoReducer.tokenUsuario,
    }
}

export default connect(mapStateToProps)(Menu);