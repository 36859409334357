import { PROMOTORES_PRE_APROVADOS } from '../types';

const reducers = {
    [PROMOTORES_PRE_APROVADOS]: (state, action) => ({
        ...state, promotores: action.payload,
        promotoresAguardando: action.payload.filter(promotor => promotor.verificado === 0)
    }),
};

const PromotorReducer = (state = { promotores: [], promotoresAguardando: [] }, action) => {
    const transform = reducers[action.type];
    if (transform) {
        return transform(state, action);
    }
    return { ...state };
}

export default PromotorReducer
