import firebase from 'firebase/app';
import 'firebase/auth';
import request from 'request';

const publicIp = require('public-ip');

export const nodeAPIURL = "https://logfreelance.tradepro.com.br"

/**
 * @name chamarNodeApi
 * @date Criado em 17/11/2020
 * @author Alexandre Poletto
 * @description Chamar a API do servidor mongo para gerar os logs
 * @param {String} funcao Função a ser chamada no node
 * @param {Object} data Informações passadas para fazer a requisição
 * @param {String} method Método que será utilizado para chamada
 */
export const chamarNodeApi = (funcao, data, method = 'GET') => new Promise(
    (resolved, rejected) => firebase.auth().currentUser.getIdToken(true).then(
        token_id => request({
            method: method,
            url: `${nodeAPIURL}/${funcao}`,
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + token_id },
            body: { ...data },
            json: true
        }, (errors, response, body) => {
            try {
                resolved(body)
            } catch (e) {
                rejected(e)
            }
        })
    )
)

/**
 * @name logging
 * @date Criado em 17/11/2020
 * @author Alexandre Poletto
 * @description Função que chama a API
 * @param {String} acao Ação requisitada
 * @param {String} data Data do log
 * @param {String} descricao Descrição do log
 * @param {String} usuario UID do usuário
 * @param {String} ip IP do usuário
 */
export const logging = async (acao, descricao, usuario, nome) => (
    chamarNodeApi('logAdmin', {
        acao,
        descricao,
        usuario,
        ip: await publicIp.v4(),
        nome
    }, 'POST')
)

/**
 * @name listlog
 * @date Criado em 17/11/2020
 * @author Alexandre Poletto
 * @description Listar os logs e paginar
 * @param {Number} pagina Número da página que será listada
 */
export const listlog = (pagina) => (
    chamarNodeApi(`logAdmin?page=${pagina}`, {}, 'PUT')
)

/**
 * @name listlogFunctions
 * @date Criado em 19/11/2020
 * @author Diego jimenes
 * @description Listar os logs das functions e paginar
 * @param {Number} pagina Número da página que será listada
 */
export const listlogFunctions = (pagina) => (
    chamarNodeApi(`LogFunction?page=${pagina}`, {}, 'PUT')
)