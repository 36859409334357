import React, { Component } from 'react';
import { Modal, Icon } from 'semantic-ui-react'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

const handleError = {
    "auth/requires-recent-login": "",
    "auth/weak-password": "perfil.view.senhas.fraca.erro",
    "auth/wrong-password": "perfil.view.senhas.errada.erro" ,
    "auth/user-mismatch": "perfil.view.senhas.crendencias.invalidas",
    "auth/invalid-credential": "perfil.view.senhas.crendencias.invalidas",
    "auth/invalid-email": "perfil.view.email.invalido",
    "auth/user-not-found": "usuario.inexistente",
    "auth/invalid-verification-code": "perfil.view.senhas.codigo.invalido",
    "auth/invalid-verification-id": "perfil.view.senhas.codigo.invalido"
}

export default class EditarPerfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            load: false,
            loadImage: false,
            errorSenha: ''
        };
    }

    reAuthenticar = (senhaAtual) => {
        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            senhaAtual
        );
        return user.reauthenticateWithCredential(credential).then((u) => {
            console.log(u)
        }).catch((err)=> console.error(err))
    }

    alterarSenha = (pass) => {
        var user = firebase.auth().currentUser;
        console.log(pass)
        return user.updatePassword(pass).then((s) => {
        })
    }

    pipelineChangePass = () => this.setState({ load: true }, () => this.reAuthenticar(this.state.senhaAtual)
        .then(() => this.alterarSenha(this.state.novaSenha)
            .then(() => this.setState({ load: false }))
            .catch((e) => Promise.reject(e)))
        .catch((er) => this.setState({ errorSenha: handleError[er.code], load: false })))

    render() {
        var condicionalSenhaNagativa = (this.state.novaSenha !== this.state.confirmSenha) && (this.state.confirmSenha !== undefined)
        var condicionalSenha = (this.state.confirmSenha !== undefined) && (this.state.confirmSenha !== '') && (this.state.novaSenha === this.state.confirmSenha)
        var condicionalSalvarSenha = (this.state.load === false) && (this.state.novaSenha !== undefined) && (this.state.novaSenha !== '')
        var style = {
            styBtn: {
                border: 'none',
                background: '#662780',
                color: "#fff",
                fontSize: 15,
                width: '100%',
                height: 40,
                borderRadius: 60,
                outline: 'none',
                cursor: 'pointer'
            },
            input: {
                height: 40,
                borderRadius: 40,
                border: '1px solid lightgray',
                outline: 'none',
                marginBottom: 10,
                paddingLeft: 10,
            }
        }
        return (
            <Modal
                closeIcon={<button style={{
                    position: 'absolute',
                    background: 'none',
                    right: 35,
                    top: 17,
                    border: 'none',
                    fontSize: 19,
                    outline: 'none',
                    cursor: 'pointer'
                }}><Icon name='times' /></button>}
                trigger={<button
                    style={{
                        border: 'none',
                        background: '#662780',
                        color: "#fff",
                        fontSize: 15,
                        width: 350,
                        height: 50,
                        borderRadius: 60,
                        outline: 'none',
                        cursor: 'pointer'
                    }}
                >
                    {'Editar Senha'}
                </button>}>
                <Modal.Header>
                    {'Editar Senha'}
                </Modal.Header>
                <Modal.Content image>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        
                        <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                            <input
                                style={style.input}
                                name="senhaAtual"
                                type='password'
                                placeholder="Senha Atual"
                                onChange={(e) => this.setState({ senhaAtual: e.target.value })} />
                            <input
                                style={style.input}
                                name="novaSenha"
                                type='password'
                                placeholder="Senha Nova"
                                onChange={(e) => this.setState({ novaSenha: e.target.value })} />
                            <input
                                style={style.input}
                                name="rSenhaAtual"
                                type='password'
                                placeholder="Repetir Senha Nova"
                                onChange={(e) => this.setState({ confirmSenha: e.target.value })} />
                            {condicionalSenhaNagativa && <p>Senhas Diferentes Tente Novamente</p>}
                            <button
                                onClick={condicionalSalvarSenha && this.pipelineChangePass}
                                style={
                                    condicionalSalvarSenha &&
                                        condicionalSenha ?
                                        style.styBtn : {
                                            ...style.styBtn,
                                            opacity: 0.2,
                                        }
                                }>{this.state.load === false ? 'Alterar Senha' : `${'carregando'}...`}</button>
                            <span style={{ textAlign: 'center', color: "red" }}>{this.state.errorSenha}</span>
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        );
    }
}
