import { db } from "../../Firebase";
import { EMPRESAS, EMPRESAS_OPORTUNIDADES, LOJAS_HOMOLOGADAS, MERCADOS, COMISSAO } from "../types";
import { chamarNodeMongo } from '../../functions/RequestFunctions'

/**
 * @name buscarEmpresas
 * @date Alterado em 17/12/20
 * @author Alexandre Poletto
 * @description Busca as empresas no firebase, envia para o redux,
 * chama a api do mongo, busca as empresas com oportunidades
 * já publicadas e envia para o redux
 */
export const buscarEmpresas = () => dispatch => {
    return new Promise((resolved, rejected) => db.collection('empresas')
        .onSnapshot(
            snap => {
                let empresas = snap.docs.map(empresa => empresa.data())
                dispatch({
                    type: EMPRESAS,
                    payload: empresas
                })
                chamarNodeMongo({ url: 'roteiro/aggregate', data: { search: [{ $match: { 'statusRoteiro.id': { $in: [3, 5] }, idEmpresa: { $in: empresas.map(e => e.uid) } } }, { $group: { _id: "$idEmpresa", count: { $sum: 1 } } }] } })
                    .then(result => dispatch({
                        type: EMPRESAS_OPORTUNIDADES,
                        payload: result
                    }))

                resolved()

            }, err => rejected(err)
        ))
};

export const buscarLojasHomologadas = () => dispatch => {
    return new Promise((resolved, rejected) => db.collection('lojasHomologadas')
        .onSnapshot(
            snap => {
                dispatch({
                    type: LOJAS_HOMOLOGADAS,
                    payload: snap.docs.map(loja => loja.data())
                });
                resolved();
            }
        ))
};

export const buscarMercados = () => dispatch => {
    return new Promise((resolved, rejected) => db.collection('mercados')
        .onSnapshot(
            snap => {
                dispatch({
                    type: MERCADOS,
                    payload: snap.docs.map(mercado => mercado.data())
                });
                resolved();
            }
        ))
};

/**
* @name salvarComissao
* @date Criado em 07/01/2020
* @author Alexandre Poletto
* @description dispatch das informações de comissão para o redux
* @param {Object} comissao comissão do vendedor
*/
export const salvarComissao = (comissao) => dispatch => (
    dispatch({
        type: COMISSAO,
        payload: comissao
    })
)
