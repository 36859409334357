import { OPORTUNIDADES_MES } from '../types';

const reducers = {
    [OPORTUNIDADES_MES]: (state, action) => ({
        ...state, oportunidadesMes: action.payload
    }),
};

export default (state = { }, action) => {
    const transform = reducers[action.type];
    if (transform) {
        return transform(state, action);
    }
    return { ...state };
}