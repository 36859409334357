import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { Container } from 'semantic-ui-react';
import './app.css';
import { auth, db } from './Firebase';
import logo from './imagens/logo96.png';
import Menu from './Menu';
import menus from './menus';
import './personalizacao/botao.css';
import './personalizacao/input.css';
import './personalizacao/modal.css';
import { autenticarUsuario, desautenticaUsuario } from './redux/actions/AutenticacaoActions';
import { buscarEmpresas } from './redux/actions/EmpresaActions';
import { buscarPromotoresAguardandoVerificacao  } from './redux/actions/PromotorActions';
import { buscarOportunidadesMes } from './redux/actions/OportunidadeActions';
import { LOGADO, NAO_LOGADO } from './redux/types';

const RouteNoMenu = ({ component: Component, ...rest }) => (
    <Component {...rest} />
)
const RouteWithMenu = ({ component: Component, ...rest }) => (
    <div style={{ height: 'auto', margin: 0 }}>
        <div>
            <Menu />
        </div>
        <Container>
            <Component {...rest} />
        </Container>
    </div>
)


const RouteComp = ({ component: Component, ...rest }) => {
    var menu = rest.menu === undefined ? true : rest.menu;
    return (
        <Route {...rest} render={props => menu ? <RouteWithMenu {...props}  {...rest} component={Component} /> : <RouteNoMenu {...props} {...rest} component={Component} />} />
    )
}

const PrivateRoute = ({ component, ...rest }) => (
    <Route
        {...rest}
        render={props => rest.firebaseReady && rest.autenticado !== LOGADO
            ? <Redirect to={{ pathname: "/login" }} />
            : <RouteComp component={component} menu {...rest} {...props} />
        }
    />
);

const UnauthenticatedRoute = ({ component, ...rest }) => (
    <Route
        {...rest}
        render={props => rest.firebaseReady && rest.autenticado === LOGADO ?
            <Redirect to={{ pathname: "/dashboard" }} /> :
            <RouteComp component={component} menu {...rest} {...props} />
        }
    />
);

const autenticarUsuarioDf = autenticarUsuario
const desautenticaUsuarioDf = desautenticaUsuario
/**
* @name onAuthStateChanged
* @date Criado em 18/12/2020
* @author Diego jimenes
* @description esta função trata as credenciais do usuario e verifica se ele tem acesso ou não 
* @param {Object} authUsuario returno do onAuthStateChanged do firebase
* @param {Function} buscarDadosDoUsuario fução que busca os dados do usuario no firestore
* @param {Function} dispatch  dispatch do redux
*/
export const onAuthStateChanged = (authUsuario, buscarDadosDoUsuario, dispatch, autenticarUsuario = autenticarUsuarioDf, desautenticaUsuario = desautenticaUsuarioDf) => {
    if (authUsuario) {
        return authUsuario.getIdTokenResult().then((token) => {
            if (token.claims.perm === 'vendedor') {
                return buscarDadosDoUsuario(authUsuario.uid, (usuario) => {
                    dispatch(autenticarUsuario(usuario, token.claims))
                })
            } else {
                dispatch(desautenticaUsuario())
                auth.signOut();
            }
        });
    } else {
        return dispatch(desautenticaUsuario())
    }
};

const dbDf = db
/**
 * @name buscarDadosDoUsuario
 * @date Criado em 18/12/2020
 * @author Diego jimenes
 * @description fução que busca os dados do usuario no firestore
 */
export const buscarDadosDoUsuario = (id, callback, db = dbDf) => {
    return db.collection('vendedores').doc(id).onSnapshot((snap) => {
        return callback(snap.data())
    })
}

class App extends Component {
    componentDidMount() {
        auth.onAuthStateChanged(
            this.onAuthStateChanged.bind(this)
        );
    }

    buscarDadosDoUsuario = buscarDadosDoUsuario

    componentDidUpdate(prevProps) {
        if (this.props.firebaseReady && (
            this.props.autenticado === LOGADO && prevProps.autenticado === NAO_LOGADO)
        ) {
            this.props.dispatch(buscarEmpresas());
            this.props.dispatch(buscarPromotoresAguardandoVerificacao());
            this.props.dispatch(buscarOportunidadesMes());
        }
    }

    onAuthStateChanged = authUsuario => {
        const { dispatch } = this.props;
        const { buscarDadosDoUsuario } = this
        onAuthStateChanged(authUsuario, buscarDadosDoUsuario, dispatch)
    };

    render() {
        return (
            !this.props.firebaseReady ? (
                <div className="not-found">
                    <div className="logo-loading-container">
                        <div className="loading-container logo-loading2"></div>
                        <div className="logo-loading loading-container"></div>
                        <img className="logo-loading-img" src={logo} alt="Logo" width="54" />
                    </div>
                </div>
            ) : <BrowserRouter>
                    <Container fluid style={{ height: "100vh" }}>
                        <Switch>
                            {menus.map(menu => {
                                if (menu.externo) {
                                    return <UnauthenticatedRoute key={menu.path} path={menu.path} exact component={menu.component} menu={menu.menu} {...this.props} />
                                } else {
                                    return <PrivateRoute key={menu.path} path={menu.path} exact component={menu.component} menu={menu.menu} {...this.props} />
                                }
                            })}
                        </Switch>
                    </Container>
                </BrowserRouter>
        );
    }
};

// provides dispatch function
export default connect(state => ({
    ...state,
    usuario: state.AutenticacaoReducer.usuario,
    autenticado: state.AutenticacaoReducer.autenticado,
    firebaseReady: state.AutenticacaoReducer.firebaseReady,
    token: state.AutenticacaoReducer.tokenUsuario,
}))(App);
