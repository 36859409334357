export const PROMOTORES_PRE_APROVADOS = 'PROMOTORES_PRE_APROVADOS';
export const OPORTUNIDADES_MES = 'OPORTUNIDADES_MES';
export const OPORTUNIDADES_NAO_EXECUTADAS = 'OPORTUNIDADES_NAO_EXECUTADAS';
export const EMPRESAS = 'EMPRESAS';
export const EMPRESAS_OPORTUNIDADES = 'EMPRESAS_OPORTUNIDADES';
export const LOJAS_HOMOLOGADAS = 'lojasHomologadas';
export const MERCADOS = 'mercados';
export const COMISSAO = 'COMISSAO';

export const AUTENTICAR_USUARIO = 'AUTENTICAR_USUARIO';
export const DESAUTENTICA_USUARIO = 'DESAUTENTICA_USUARIO';

export const LOGADO = 'LOGADO';
export const NAO_LOGADO = 'NAO_LOGADO';