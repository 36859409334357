import moment from 'moment'

/**
* @name TOTAL_TAXA_VENDEDOR
* @date Criado em 06/01/2020
* @author Alexandre Poletto
* @description buscar no mongodb a comissão do
* vendedor dos últimos 3 meses
* @param {Object} empresa empresa a pesquisar
*/
export const TOTAL_TAXA_VENDEDOR = (empresa) => [
    {
        $match: {
            'idEmpresa': empresa.uid,
            'statusRoteiro.id': {
                $in: [3, 5]
            },
            data: {
                $gte: empresa.vendedor.data,
                $lte: moment(empresa.vendedor.data).add(3, "months").format('YYYY-MM-DD')
            }
        }
    },
    {
        $group: {
            _id: {
                idEmpresa: "$idEmpresa",
                fantasiaEmpresa: '$fantasiaEmpresa',
                ano: {
                    "$year": { "$dateFromString": { "dateString": "$data" } }
                },
                mes: {
                    "$month": { "$dateFromString": { "dateString": "$data" } }
                }
            },
            taxa: {
                $sum: '$pagamento.taxa'
            },
            count: {
                $sum: 1
            }
        }
    },
]

export const queriePorData = (dados) => [
  {
      $match: {
          'arquivado': false,
          data: {
              $gte: dados.dataInicio,
              $lte: dados.dataFinal
          }
      }
  }
]

export const querieExecutadasMes = (dados) => [
  {
      $match: {
          'arquivado': false,
          'statusRoteiro.id': { $in: [3, 5] },
          'data': {
              $gte: dados.dataInicio,
              $lte: dados.dataFinal
          },
      },
  },
  {
      $group: {
          _id: {
              ano: {
                  "$year": { "$dateFromString": { "dateString": "$data" } }
              },
              mes: {
                  "$month": { "$dateFromString": { "dateString": "$data" } }
              },
          },
          quantidade: { $sum: 1 },
      }
  }
]